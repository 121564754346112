<template>

<app-wrapper>

	<h1 class="article-title">{{ $state.page.name }}</h1>

	<div class="article-date">{{ $state.page.date | formatDate('DD/MM/YYYY') }}</div>

	<img class="article-image" :src="$state.page.image" v-if="$state.page.image" />

	<div class="article-description">{{ $state.page.description }}</div>

	<app-text :text="$state.page.content" />

	<div class="article-tags"><b>Topics:</b><router-link v-for="(tag, index) in $state.page.tags" :key="index" :to="{name: 'News', query: { topic: tag.slug }}">{{ tag.name }}</router-link></div>
	
	<div class="article-author" v-if="$state.page.author">

		<div class="article-author-image" v-bgimage="$state.page.author.image" />
		<div class="article-author-name">{{ $state.page.author.name }}</div>
		<div class="article-author-role">{{ $state.page.author.role }}</div>
		<div class="article-author-email" v-if="!window.is.mobile"><a :href="'mailto:' + $state.page.author2email">{{ $state.page.author.email }}</a></div>
		<div class="article-author-email" v-if="window.is.mobile"><a :href="'mailto:' + $state.page.author.email">{{ emailPreview }}</a></div>
		<div class="article-author-phone"><a :href="'tel:' + $state.page.author.phone">{{ $state.page.author.phone }}</a></div>

	</div>
	
	<div class="article-author is-second" v-if="$state.page.author2">

		<div class="article-author-image" v-bgimage="$state.page.author2.image" />
		<div class="article-author-name">{{ $state.page.author2.name }}</div>
		<div class="article-author-role">{{ $state.page.author2.role }}</div>
		<div class="article-author-email" v-if="!window.is.mobile"><a :href="'mailto:' + $state.page.author2.email">{{ $state.page.author2.email }}</a></div>
		<div class="article-author-email" v-if="window.is.mobile"><a :href="'mailto:' + $state.page.author2.email">{{ emailPreview2 }}</a></div>
		<div class="article-author-phone"><a :href="'tel:' + $state.page.author2.phone">{{ $state.page.author2.phone }}</a></div>

	</div>

</app-wrapper>

</template>

<script>

export default {

	computed: {

		emailPreview: function() {

			return this.$state.page.author.email.split('@')[0]

		},

		emailPreview2: function() {

			return this.$state.page.author2.email.split('@')[0]

		}

	}

}

</script>

<style scoped>

.article-title {
	font-size: 36px;
	font-weight: 700;
	color: #1A2046;
	line-height: 48px;
	margin-bottom: 20px;
}

.is-tablet .article-title {
	font-size: 36px;
	line-height: 48px;
}

.is-mobile .article-title {
	font-size: 24px;
	line-height: 30px;
}

.article-date {
	color: #FF2D07;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 40px;
}

.is-mobile .article-date {
	font-size: 13px;
	margin-bottom: 32px;
}

.article-image {
	width: 100%;
	height: auto;
	margin-bottom: 40px;
}

.article-description {
	margin-bottom: 40px;
	font-size: 26px;
	line-height: 34px;
	color: #45464B;
	font-weight: 400;
	padding-bottom: 40px;
}

.is-tablet .article-description {
	font-size: 20px;
	line-height: 24px;
}

.is-mobile .article-description {
	font-size: 18px;
	line-height: 24px;
}

.article-description:after {
	content: '';
	display: block;
	position: absolute;
	left: 0px;
	bottom: -2px;
	background-color: #FF2D07;
	height: 4px;
	width: 120px;
}

.article-author {
	background-color: #EDEFFA;
	border-radius: 10px;
	padding: 40px 40px 40px 260px;
	margin-top: 100px;
	min-height: 220px;
}

.article-author.is-second {
	margin-top: 20px;
}

.is-tablet .article-author {
	margin: 48px 0px;
}

.is-mobile .article-author {
	margin: 32px 0px;
	padding: 20px;
	min-height: auto;
}

.article-author-image {
	position: absolute;
	width: 200px;
	height: 200px;
	left: 20px;
	top: 20px;
	background-position: 50% 50%;
	background-size: contain;
}

.is-mobile .article-author-image {
	position: relative;
	margin: 0px auto;
	left: auto;
	top: auto;
	margin-bottom: 20px;
}

.article-author-name {
	color: #1A2046;
	font-size: 22px;
	font-weight: 700;
}

.is-device .article-author-name {
	font-size: 18px;
}

.is-mobile .article-author-name {
	text-align: center;
}

.article-author-phone,
.article-author-email,
.article-author-role {
	color: #45464B;
	font-size: 16px;
	font-weight: 500;
	line-height: 44px;
}

.is-mobile .article-author-role {
	text-align: center;
	line-height: 20px;
	margin-top: 5px;
	margin-bottom: 20px;
}

.is-device .article-author-phone,
.is-device .article-author-email,
.is-device .article-author-role {
	font-size: 14px;
}

.article-author-phone,
.article-author-email {
	padding-left: 64px;
	background-size: 44px 44px;
	background-position: 0px 50%;
	background-repeat: no-repeat;
}

.article-author-email {
	margin-bottom: 10px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAXVBMVEUAAABIXMdIXMdHWshHXMhHXMdIXMdIXMdHXcdIW8dIW8hIXMdIW8hHW8VJXMdJXcZIXMf///92hdV1hNXR1vGkruP09fu7wupqe9L09fzGze6vuOeYpOCBj9lfcM57IqXVAAAAEHRSTlMA3sYxY/anioTtuJt4c1s/p7rgsAAAAgVJREFUWMOs1G2ugyAUhGE4FEXrxzQ3WtPuf5/XP+bEaBkU3gU8ISNiEnJDqMQ2HvCNlSoMzuTnutbjkG87l6XWFj+z9V37ISDJ4wbbWyRk+6unVZbRV049Ci4kY6pb42J1EusElxOXsC5uRZcOuFmIu0/c7hlzK2RUETdDJjsUXyMgu1DunvFb51Akd4AFRZLk9yHz3RhRrLHsEJqQG1HmZlgUzKrbo2h92QNrliycv7KgcEJ+5uWPtOBHLvrTfV+0L86ro59ufvFwno0u8UootkVH4M900ofA3Qq3BJ4XHFpmArcr7KOwynuXwF4nPoffm3x03zEYzgxRGJt8dBGFBxPi8CYf3TgcTEVglfcugSsjBFZ55zJYjKWwyupS2JqGwSqry+HGeAarPE3qMtgbEFjlNXUZjP/mzCAHQhAIgkQDuheDBA4k6v+fuXvjtD1s1or0A+qgJDNTbYEbuXH7wL4PHEvdP6kl9oG99fNarqOU44qd4Jf13ESM5zYx4MkFBhzcyoBXtzHgzc0MeDZGUxZRYK+Gad7N5K/DVI3/ZIOTGP/iI59VY+spPrHejpOMtSEv8fYsbY29NzO1eGOnAnXcYOcYdUBiJy91pGNagRIhlLrBZBOlxyihxypILU0H0rGBEtOUSqfkP1VXQAULVQk9X2L9UruNUxT2VJvjlbH/1MdvUVN+dcQCiMQAAAAASUVORK5CYII=');
}

.article-author-phone {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAS1BMVEUAAABIXMdHWshIXMdHXMhHW8dIXMdIXMdHXcdIXcdIXMZIW8hIXMdIW8hHW8VIXMZJXcZIXMf///9fcM709fzGze7p6/jo6/jGzO1bgxbZAAAAEXRSTlMA3jHEX/CnioT4yribeHNnP9HkXvAAAAGoSURBVFjDtNVBroMwDEVRO05CgEJ5QEX3v9KvP2vVpg4hvkMGR8jChgpyPkXh0AN9YInJO7qem8aAj8I4uUtqx8jGXa3tBUriK9iZURDPJ9k7ozC+U3mL4ESylLodTtYVsW7A6Qanux5Vec1NqCz9dm+o7vbLjbhQNHKBqMyh+TQSLpfafWf6V+fQJPcBD2jSUHEfqu7GgmYtb7CgWfJ219Gw18vPaBi//N+QbXse65eO54Zsc8ELb/uaac/LXLBzjzXbQ98/QbY9Dx/IJvoy/wu55+pid5Wwun7cHmZlEgqszGKygCciGi3gkYiCBRyIHCxgOPI2sKdkAyeKNnAksYGF2AZmCjZwoN4G7gk2MP6as2MbAEIYhqIV11HC/qPeAiCD0BN0VCmAJPY3K8yugj0e+26sQVhLsyHkxmYe9LNzsgxFFC6zZZoL52VaReHKBIuSWEwUMhl7Irx7EN7EKkBzk+1Y68N7aMGOEQPJLK8y6QwrKBCi0A2DTQqPOaDnEWSGptdx7KfAtELpCv6ruAIFLCoSuh9i7cRu7wSFK9Hme2HsSXz8A+EskoX8iHBPAAAAAElFTkSuQmCC');
}

.article-tags {
	margin-top: 40px;
}

.article-tags b {
	font-weight: 600;
    font-size: 16px;
    max-width: 89%;
    letter-spacing: 0px;
    line-height: 28px;
    color: #333;
	margin-top: 20px;
	margin-right: 10px;
}

.article-tags a {
	font-weight: 500;
    font-size: 16px;
    max-width: 89%;
    letter-spacing: 0px;
    line-height: 28px;
    color: #999;
	margin-top: 20px;
	border-right: 1px solid red;
	padding-right: 10px;
	margin-right: 10px;
}

.article-tags a:last-child {
	border-right: 0px;
}

.article-tags a:hover {
	text-decoration: underline;
}

</style>
